import { useState, useEffect } from 'react';
import SearchPageModel from '../../SearchPage/Models/SearchPageModel.interface';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import { SearchTypes, InitSearchResult, GetSearchResult } from './Search';
import { styled } from '../../stitches.config';
import ProductCard from '../../Organisms/ProductCard/ProductCard';
import Dots from '../../Atoms/Loaders/Dots';
import LoadMoreContainer from '../../Molecules/LoadMoreContainer/LoadMoreContainer';
import { useAppSettingsData } from '../../Shared/Providers/AppSettingsProvider';
import { getUrlParameter, updateUrl } from '../../Shared/Common/Helpers';
import {
  EventDispatcher,
  ON_SEARCH,
} from '../../Shared/Common/EventDispatcher';
import IProductCardModel from '../../ProductCard/Models/ProductCardModel.interface';
import { GridWidth } from '../../Enums/GridSize.enum';
import Grid from '../../Atoms/Grids/Grid';
import { useKexLoadingCircle } from '../../Kex/KexLoadingCircle';
import FullSearchResult from '../../Search/Models/FullSearchResult.interface';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import ContentContainer from '../../Molecules/ContentContainer/ContentContainer';
import ContentModel from '../../Search/Models/ContentModel.interface';
import KexLink from '../../Kex/KexLink';
import SearchLinkResult from '../../Search/Models/SearchLinkResult.interface';

function SearchPage() {
  const {
    staticPages: { searchPage },
    languageRoute,
    startPageId,
  } = useAppSettingsData();
  const {
    'searchPage/products': products,
    'search/pages': pagesLabel,
    'search/category': categories,
    'categoryPage/productCounter': productCounterLabel,
    'searchPage/contentCounter': contentCounterLabel,
    'categoryPage/loadMore': loadMoreText,
    'searchPage/enterQuery': enterQueryLabel,
    'searchPage/noHits': noHitsLabel,
    'searchPage/searchResultsFor': searchResultLabel,
  } = useTranslationData();

  const { pageTitle, channelId, pageId, itemsPerLoad } =
    useCurrentPage<SearchPageModel>();

  const [activeTab, setActiveTab] = useState<SearchTypes>(SearchTypes.Products);
  const [searchResult, setSearchResult] = useState<FullSearchResult>();
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const requestContext = JSON.stringify({
    currentPageSystemId: startPageId,
    categorySystemId: pageId,
    channelSystemId: channelId,
  });

  const onSearch = (value: boolean) => {
    !searchResult && setIsSearching(value);
  };

  useEffect(() => {
    EventDispatcher.subscribe(ON_SEARCH, onSearch);
    return () => {
      EventDispatcher.unsubscribe(ON_SEARCH, onSearch);
    };
  });

  var query = getUrlParameter('searchQuery');

  const dispatchLoading = useKexLoadingCircle();

  const totalHits = searchResult?.totalHits;
  const [listView, setListView] = useState(false);

  useEffect(() => {
    dispatchLoading('add');
    InitSearchResult(itemsPerLoad, query, requestContext, languageRoute).then(
      (data) => {
        setSearchResult(data);
        if (
          data?.productSearchResult &&
          data.productSearchResult?.numberOfItems > 0
        ) {
          setSearchResult(data);
        }
      }
    );
    dispatchLoading('remove');
  }, [query, dispatchLoading, languageRoute, requestContext]);

  const searchResults =
    activeTab === SearchTypes.Products
      ? searchResult?.productSearchResult
      : searchResult?.contentSearchResult;

  const loaded =
    activeTab === SearchTypes.Products && searchResult
      ? (searchResult.productSearchResult?.items.length /
          searchResult.productSearchResult?.availableItems) *
        100
      : activeTab === SearchTypes.Content && searchResult
      ? (searchResult.contentSearchResult?.items.length /
          searchResult.contentSearchResult?.availableItems) *
        100
      : 0;

  const counterText =
    searchResults && productCounterLabel && activeTab === SearchTypes.Products
      ? productCounterLabel
          .replace('{0}', searchResults?.items.length.toString())
          .replace('{1}', searchResults?.availableItems.toString())
      : searchResults &&
        contentCounterLabel &&
        activeTab === SearchTypes.Content
      ? contentCounterLabel
          .replace('{0}', searchResults?.items.length.toString())
          .replace('{1}', searchResults?.availableItems.toString())
      : '';

  const noHits =
    !searchResult?.productSearchResult &&
    !searchResult?.contentSearchResult &&
    !searchResult?.categorySearchResult;

  const resultText = totalHits === 0 ? '' : `${searchResultLabel} ${query}`;

  useEffect(() => {
    switch (activeTab) {
      case SearchTypes.Content:
        if (searchResult?.contentSearchResult?.availableItems === 0) {
          setActiveTab(SearchTypes.Products);
        }
        break;
      case SearchTypes.Products:
      default:
        break;
    }
  }, [searchResult, activeTab, setActiveTab]);

  const disableButton = () => {
    switch (activeTab) {
      case SearchTypes.Products:
        if (
          searchResult?.productSearchResult?.items.length ===
          searchResult?.productSearchResult?.availableItems
        ) {
          return true;
        }
        break;
      case SearchTypes.Content:
        if (
          searchResult?.contentSearchResult?.items.length ===
          searchResult?.contentSearchResult?.availableItems
        ) {
          return true;
        }
        break;
    }
    return false;
  };

  const fetchMoreProducts = () => {
    const loadedItems = searchResult?.productSearchResult.items.length;
    if (!query || !searchResult) return;
    dispatchLoading('add');
    GetSearchResult(
      query,
      requestContext,
      languageRoute,
      Number(itemsPerLoad),
      SearchTypes.Products,
      searchResult,
      loadedItems
    ).then((data) => {
      if (data) {
        setSearchResult(data);
        dispatchLoading('remove');
      }
    });

    const url = `${searchPage}?searchQuery=${query}&items=${
      loadedItems ? loadedItems + Number(itemsPerLoad) : ''
    }`;
    updateUrl(pageTitle, url);
  };

  return (
    <Main>
      <TopContainer>
        <QueryHeader>
          {isSearching ? (
            <Dots />
          ) : !!!query.length ? (
            enterQueryLabel
          ) : noHits ? (
            noHitsLabel
          ) : !(Object.keys(searchResult).length === 0) ? (
            `${resultText}`
          ) : (
            ''
          )}
        </QueryHeader>
      </TopContainer>
      <ContentContainer>
        {searchResult?.productSearchResult && (
          <>
            <div>
              {searchResult?.productSearchResult.availableItems} {products}
            </div>
            <StyledGrid width={GridWidth.ContentWidth} listViewGrid={listView}>
              {searchResult?.productSearchResult?.items.map(
                (product: IProductCardModel) => (
                  <ProductCard
                    key={product.code}
                    item={product}
                    isListView={listView}
                  />
                )
              )}
            </StyledGrid>
          </>
        )}
        {searchResult?.productSearchResult && (
          <LoadMoreContainer
            loaded={loaded}
            counterText={counterText}
            loadMore={loadMoreText}
            disabled={disableButton()}
            onClick={() => fetchMoreProducts()}
          />
        )}
        {searchResult?.categorySearchResult && (
          <PageResultsContainer>
            <PageResultsWrapper>
              {searchResult?.categorySearchResult.length} {categories}
            </PageResultsWrapper>
            <PageResultsWrapper>
              {searchResult?.categorySearchResult.map(
                (category: SearchLinkResult) => (
                  <KexLink key={category.id} href={category.url}>
                    {category.name}
                  </KexLink>
                )
              )}
            </PageResultsWrapper>
          </PageResultsContainer>
        )}
        {searchResult?.contentSearchResult && (
          <PageResultsContainer>
            <PageResultsWrapper>
              {searchResult?.contentSearchResult.availableItems} {pagesLabel}
            </PageResultsWrapper>
            <PageResultsWrapper>
              {searchResult?.contentSearchResult.items.map(
                (content: ContentModel) => (
                  <KexLink key={content.src} href={content.src}>
                    {content.name}
                  </KexLink>
                )
              )}
            </PageResultsWrapper>
          </PageResultsContainer>
        )}
      </ContentContainer>
    </Main>
  );
}

export default SearchPage;

const Main = styled('main', {
  backgroundColor: '$white',
});

const TopContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  py: 8,
  '@mediaMaxMedium': {
    px: 4,
  },
  '@mediaMinLarge': {
    py: 12,
  },
});

const PageResultsContainer = styled('div', {
  pb: 8,
  '@mediaMinLarge': {
    pb: 16,
  },
});

const PageResultsWrapper = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '16px',
  mt: 4,
});

const QueryHeader = styled('h1', {
  fs: 10,
  fontWeight: '$fw700',
  ls: '$ls125',
  textAlign: 'center',
  my: 0,
  '@mediaMinLarge': {
    fs: 12,
  },
});

const StyledGrid = styled(Grid, {
  mt: 4,
  pb: 8,
  '@mediaMinLarge': {
    g: 8,
    mt: 6,
    pb: 16,
  },
});
